import React from "react";
import { Typography } from "@mui/material";
import { Helmet } from "react-helmet"; // If you still want to include structured data

export default function HomePage() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: "#fff8e1",
        padding: "20px",
        textAlign: "center",
      }}
    >
      {/* <Helmet>
        <title>Mystics Leaf - Important Notice</title>
      </Helmet> */}
      
      {/* Notification Message */}
      <div style={{ padding: "20px", backgroundColor: "#ffeb3b", borderRadius: "8px" }}>
        <Typography variant="h4" component="h1" style={{ color: "#d32f2f", marginBottom: "10px" }}>
          Important Notice: Mystics Leaf Operations Paused
        </Typography>
        <Typography variant="h6" component="p" style={{ color: "#000" }}>
          We regret to inform you that Mystic Leaf has stopped operations in Canada starting today.
          
        </Typography>
        <Typography variant="body1" component="p" style={{ marginTop: "20px", color: "#000" }}>
          Thank you for your continued support, and we hope to serve you again in the future.
        </Typography>
      </div>
    </div>
  );
}
